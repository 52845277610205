import '../styles/index.scss';

import $ from 'jquery'
import '@popperjs/core';
import AOS from 'aos';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'

import bootstrap from 'bootstrap';
import { ScrollSpy, Collapse } from 'bootstrap';
import 'bootstrap/js/dist/scrollspy';



$(window).on('scroll load', function() {
    if ($(".navbar").offset().top > 60) {
        $(".fixed-top").addClass("top-nav-collapse");
    } else {
        $(".fixed-top").removeClass("top-nav-collapse");
    }
});

AOS.init();

$( document ).ready(function() {
new ScrollSpy(document.body, {
    target: '#navbar'
  })
});

const navLinks = document.querySelectorAll('.nav-link');
const menuToggle = document.getElementById('navbarSupportedContent');
const bsCollapse = new Collapse(menuToggle, {toggle:false})
navLinks.forEach((l) => {
    l.addEventListener('click', () => { bsCollapse.toggle() })
})
